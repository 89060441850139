div.container {
  border-left: 2px solid #00ff00;
  border-right: 2px solid #00ff00;
  padding: 0 10%;
  text-align: center;
  margin: 2% 5%;
  display: flex;
  justify-content: space-between;
}

li.container {
  font-size: 15px;
}

.link {
  font-size: 18px;
  color: white;
  text-decoration: none;
}

.link:visited {
  color: white;
  text-decoration: none;
}