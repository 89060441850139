/* Links and Visited Links */
a,
a:visited {
  color: #3b3bb3;
  /* Green for links */
  text-decoration: none;
  /* Remove underlines from links */
}

/* Main Body */
.title {
  color: #fff;
  /* White for titles */
}

.titleContainer,
div.post {
  max-width: 1024px;
  box-sizing: border-box;
  margin: 36px auto;
  padding: 8px 5% 0;
  /* Adjusted padding to reduce the gap between title and body */
}

div.post {
  color: #ccc;
  /* Light gray for text content */
  background-color: #000;
  /* Black background */
  padding: 16px 32px 4% 32px;
  line-height: 1.5;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

div.post p {
  line-height: 1.5em;
}

div.post ul li {
  padding-bottom: 1%;
}

/* Headings */
.post h1,
.post h2 {
  color: white;
  /* Green for headings */
  text-decoration: underline;
}

/* Images */
.post img {
  width: 100%;
  max-width: 600px;
  display: block;
  margin: 32px auto;
  border-radius: 8px;
}

/* Inline Code Blocks */
.post code {
  color: #00ff00;
  /* Green for code */
  background-color: #333;
  /* Dark background for code blocks */
  padding: 2px;
  /* Add padding to the inline code blocks */
  overflow-wrap: break-word;
  word-wrap: break-word;
}

/* Title Container */
.titleContainer {
  background-color: #000;
  /* Black background for title container */
  padding: 8px 32px;
  /* Adjusted padding to reduce the gap */
}

.titleContainer h1 {
  margin-top: 0;
}

/* Preformatted Code Blocks */
.post pre code {
  font-size: 14px;
  white-space: pre-wrap;
  background-color: #222;
  /* Dark background for code blocks within pre tags */
}

/* Tables */
.post table {
  margin: 0 auto;
  /* Center the table */
  width: 100%;
}

.post th,
.post td {
  border-bottom: 1px solid #333;
  /* Darker border color for table cells */
  padding: 15px;
}

/* Paragraphs */
.post p {
  margin: 8px 0;
}

/* Media Queries */
@media screen and (max-width: 1100px) {

  .titleContainer,
  div.post {
    margin: 36px;
    padding: 8px 5% 0;
    /* Adjusted padding to reduce the gap between title and body */
  }
}

@media screen and (max-width: 480px) {
  div.post {
    padding: 16px;
  }
}