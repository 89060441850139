.center {
  margin: 5%;
}

div.boxedAbout {
  width: 100%;
  max-width: 600px;
  display: block;
  margin: 32px auto;
  border-radius: 8px;
  color: white;
}

.ruseImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin: auto;
  width: 30%;
  height: 10%;
}

a,
a:visited {
  color: #00ff00;
  /* Green for links */
  text-decoration: none;
  /* Remove underlines from links */
}