/* Page Title */
.pageTitle {
  color: #00ff00;
  border-left: 2px solid #00ff00;
  padding-left: 2%;
  font-size: 20px;
  margin-left: 5%;
}

/* Pinned Blogs Container */
.pinnedBlogs {
  background-color: #000;
  margin: 1% 5% 1% 5%;
  padding: 10px;
  border: 2px solid #00ff00;
  border-radius: 5px;
}

.pinnedBlogs h1 {
  color: #00ff00;
  font-size: 18px;
  margin: 10px 0;
}

.pinnedBlogs h2 {
  color: #00ff00;
  margin: 10px 0;
}

/* Card Container */
.cardDiv {
  margin: 3% 5% 0 5%;
}

/* Year Filter Horizontal List */
.horizontalList {
  display: flex;
  margin: 1% 5% 0 5%;
  list-style: none;
  padding: 0;
}

/* Year Buttons */
.yearButton,
.selectedYear {
  background: none;
  border: none;
  color: #00ff00;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
  margin-right: 10px;
  padding: 5px;
}

/* Make the selected year button stand out */
.selectedYear {
  font-weight: bold;
}

/* Hover effect for year buttons */
.yearButton:hover {
  background-color: #000;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {

  /* Adjust styles for smaller screens */
  .pageTitle {
    font-size: 16px;
    /* Slightly decrease font size */
    margin-left: 3%;
  }

  .pinnedBlogs {
    margin: 1% 5% 1% 5%;
    font-size: 16px;
    /* Slightly decrease font size */
  }

  .pinnedBlogs h1 {
    font-size: 16px;
    /* Slightly decrease font size for h1 */
  }

  .pinnedBlogs h2 {
    font-size: 14px;
    /* Slightly decrease font size for h2 */
  }

  .cardDiv {
    margin: 3% 5% 0 5%;
    font-size: 14px;
    /* Slightly decrease font size for card text */
  }

  .horizontalList {
    margin: 1% 5% 0 5%;
    font-size: 14px;
    /* Slightly decrease font size for year buttons */
  }

  .yearButton,
  .selectedYear {
    font-size: 14px;
    /* Slightly decrease font size for year buttons */
    margin-right: 10px;
    padding: 5px;
  }
}


.error {
  color: red;
  font-weight: bold;
  margin-top: 20px;
}
