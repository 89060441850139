/* ---------------- cryto --------------------- */

div.container {

  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  margin-top: 15%;
}

ul.crypto {
  list-style-type: none;
}

li.crypto {
  float: left;
  border-bottom: 1px solid #00ff00;
  margin: 0 50px 0 0;
  font-size: 15em;
}



@media all and (max-device-width: 720px) {
  li.crypto {
    font-size: 2em;
  }

  div.container {
    margin-top: 25%;
  }

}